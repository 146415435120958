import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../../../components/layout/layout"
import SEO from "../../../components/seo"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import styled from "styled-components"
import { device } from '../../../components/device'

import Sidebar from "../../../components/sidebar/sidebar"
import Banner from "../../../components/vinhos/vinhosBanner"
import Vinhos from "../../../components/vinhos/vinhosCardList"
import Footer from "../../../components/footer/footer"
import SidebarMobile from '../../../components/sidebar/mobile'
import WineFilter from '../../../components/sidebar/wine-filter'

const DaoPage = ({data}) => {
  const breakpoints = useBreakpoint();

  function changeSelection(selec) {
    if(selec==="none"){
      setFiltrados(data.vinhosJson.docs.dao);  
    }
    else{
      setFiltrados(data.vinhosJson.docs.dao.filter(data => data.tipo===selec));
    }
  }
  function getTypes(){
    var typeTemp={
      vb: false,
      vt: false,
      vr: false,
      vl: false,
      es: false,
      vp: false
    }
    data.vinhosJson.docs.dao.forEach(vinho => {
      switch(vinho.tipo){
        case "Vinho Branco":
          typeTemp.vb = true;
          break;
        case "Vinho Tinto":
          typeTemp.vt = true;
          break;
        case "Vinho Rosé":
          typeTemp.vr = true;
          break;
        case "Vinho Licoroso":
          typeTemp.vl = true;
          break;
        case "Espumante":
          typeTemp.es = true;
          break;
        case "Vinho do Porto":
          typeTemp.vp = true;
          break;
        default:
          break;
      }
    });
    return(typeTemp);
  }
  const [types] = useState(getTypes());
  const [filtrados, setFiltrados] = useState(data.vinhosJson.docs.dao);

  return (
    <Layout color="#150e04">
      <SEO title="Dão" />
      {!breakpoints.mobile ?
        <Sidebar switchMenu="right" menuBtn/>
      :
        <SidebarMobile brown/>
      }
      {breakpoints.mobile &&
        <WineFilter changeFilter={changeSelection} types={types}  white="isWhite"/>
      }
      <Spacing>
        <Banner data={data.vinhosJson.banners.docs.dao} vinho='dao' changeFilter={changeSelection} types={types}/>
        <Vinhos data={filtrados} full={true}/>
      </Spacing>
      <Footer/>
    </Layout>
  )
}

export default DaoPage

const Spacing = styled.div`
  position:relative;

  @media ${device.desktop}{
    margin-left: 200px;
    margin-right: 14px;
  }

  @media ${device.desktopL}{
    margin-left: 15.65%;
  }
`

export const Json = graphql`
  query dao {
    vinhosJson{
      banners{
        docs{
          dao{
            banner{
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      docs{
        dao{
          nome
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
          ano
        }
      }
    }
  }
`